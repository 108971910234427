(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:EntityTypesPublic
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('EntityTypesPublic', EntityTypesPublic);

  function EntityTypesPublic($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/public/entityTypes');
  }
}());
